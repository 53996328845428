import React from "react";
import ThreeColumnGridCard from "./GridCard/ThreeColumnGridCard";
import type { ThreeColumnGridCardProp } from "./GridCard/ThreeColumnGridCard";

const contents: ThreeColumnGridCardProp[] = [
  {
    title: "インシデント対応の自動化",
    to: "/incident-response",
    linkText: "製品の特徴",
    image: (
      <img src={"/icons/zap.svg"} alt="Zap" className="object-cover w-10 " />
    ),
    cardText:
      "PagerDutyとRundeckを併用することにより、インシデント対応ライフサイクル全体が自動化できるため、迅速な障害解決及びインシデント削減が可能となります。",
  },
  {
    title: "サービスリクエストの自動化",
    to: "/service-requests",
    linkText: "製品の特徴",
    image: (
      <img
        src={"/icons/aperture.svg"}
        alt="Aperture"
        className="object-cover w-10"
      />
    ),
    cardText:
      "Runbook AutomationでOpsアクションへのセルフサービスアクセスを有効に。貴重なリソースは重要な仕事に割り当てましょう。",
  },
  {
    title: "セキュリティとコンプライアンスの自動化",
    to: "/security-and-compliance",
    linkText: "製品の特徴",
    image: <img src={"/icons/lock.svg"} alt="Lock" className="w-10 " />,
    cardText:
      "コンプライアンスとセキュリティ体制を強化します。Rundeckは、最も規制に適合した安全な環境でセルフサービスを可能にします。",
  },
];

const ThreeColumnGridCards = () => {
  return (
    <div className="mt-[96px]">
      <h3 className="mb-8 text-center md:mb-12 mb-8 dsc-h3 font-bold md:w-full w-[70%] mx-auto">
        Runbook Automationソリューション
      </h3>
      <div className="flex flex-wrap justify-start p-4 md:p-0 md:gap-x-8 xl:gap-x-10 gap-y-16 md:px-8">
        {contents.map((_, index) => (
          <ThreeColumnGridCard
            title={_.title}
            cardText={_.cardText}
            to={_.to}
            linkText={_.linkText}
            image={_.image}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default ThreeColumnGridCards;
