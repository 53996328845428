import * as React from "react";
import Hero from "../components/home/hero";
import Container from "../layout/container";
import Features from "../components/home/grid/ThreeColumnGridCards";
import Articles from "../components/home/news";
import Meta from "../components/common/meta";
const IndexPage = () => {
  return (
    <Container>
      <Meta />
      <Hero></Hero>
      <Features />
      <Articles></Articles>
    </Container>
  );
};

export default IndexPage;
