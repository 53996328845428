import React from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";
import Link from "../../../common/link";

export type ThreeColumnGridCardProp = {
  title: string;
  subTitle?: string;
  cardText: string;
  to: string;
  linkText: string;
  image?: React.ReactNode;
};

const ThreeColumnGridCard = ({
  title,
  subTitle,
  cardText,
  to,
  linkText,
  image,
}: ThreeColumnGridCardProp) => {
  return (
    <div className="w-full md:w-[30%] flex flex-col font-normal">
      <div className="flex flex-col gap-4 mb-6 min-h-14">
        {image && image}
        <h4 className="font-bold dsc-h4 text-dsc-theme-muted-500 ">{title}</h4>
      </div>
      {subTitle && (
        <p className="mb-2 text-lg text-dsc-theme-muted-500 ">{subTitle}</p>
      )}
      <p className="mb-4 text-dsc-theme-muted-500 dsc-text-sm leading-[24px]">
        {cardText}
      </p>
      <Link
        to={to}
        className="flex  items-center gap-2 transition-all duration-300 text-dsc-theme-accent group 
      [&_svg]:w-8 [&_svg]:h-8 [&_svg]:text-white [&_svg]:bg-dsc-theme-accent [&_svg]:p-2 [&_svg]:rounded-full [&_svg]:transition-all [&_svg]:duration-300 [&_svg]:hover:scale-125 font-bold"
      >
        {linkText} <ArrowRightIcon />
      </Link>
    </div>
  );
};

export default ThreeColumnGridCard;
