import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import moment from "moment";
import "moment/locale/ja";

const Articles = () => {
  const { allStrapiNew } = useStaticQuery(graphql`
    query HomeNewArticle {
      allStrapiNew(limit: 3, sort: { fields: releaseDate, order: DESC }) {
        edges {
          node {
            id
            slug
            title
            description
            releaseDate
          }
        }
      }
    }
  `);
  const extraData = [
    {
      node: {
        description: "",
        id: "123123",
        releaseDate: "2020-09-21",
        title:
          "PagerDutyがRundeckを買収し、デジタルオペレーション全体でDevOps自動化機能を拡張",
        slug: "https://investor.pagerduty.com/news/news-details/2020/PagerDuty-to-Acquire-Rundeck-to-Expand-DevOps-Automation-Capabilities-Across-Digital-Operations/default.aspx",
      },
    },
    {
      node: {
        description: "",
        id: "123",
        releaseDate: "2020-10-01",
        title:
          "PagerDutyがDevOpsオートメーションの最先端にして革新者のRundeckの買収を完了",
        slug: "https://investor.pagerduty.com/news/news-details/2020/PagerDuty-Completes-Acquisition-of-DevOps-Automation-Leader-and-Innovator-Rundeck/default.aspx",
      },
    },
  ];
  extraData.forEach((dat) => {
    allStrapiNew.edges.push(dat);
  });
  const checkDx = (link) => {
    if (link.includes("http")) return "Rundeck 関連";
    else {
      return "コンテンツ更新情報";
    }
  };
  const checkLink = (link) => {
    if (link.includes("http")) return true;
    else {
      return false;
    }
  };
  const data = allStrapiNew.edges.sort(function (a, b) {
    return new Date(b.node.releaseDate) - new Date(a.node.releaseDate);
  });

  return (
    <section className="flex flex-col font-normal mb-[96px] mt-[120px]">
      <h2 className="font-bold leading-relaxed text-center uppercase dsc-h3 ">
        RUNDECK の最新情報
      </h2>
      <p className="w-full my-4 font-normal text-center text-dsc-theme-muted-500 dsc-text">
        {"Rundeck関連のお知らせ・コンテンツ更新情報​"}
      </p>
      <div className="md:px-12 md:w-[70%] lg:w-[80%] md:mx-auto flex flex-col gap-2 p-4 md:py-0 border-gray-200 rounded-md cursor-pointer">
        {data.map((itm) => (
          <div
            key={itm.node.id}
            className="flex flex-col items-center justify-between gap-4 py-4 border-b md:py-6 md:flex-row md:gap-6 md:justify-start border-b-gray-200"
          >
            <div className="flex items-center justify-start w-full gap-4 md:contents">
              <p className="max-w-max min-w-[120px]">
                {moment(itm.node.releaseDate).format("ll")}
              </p>
              <button
                className={`px-4  min-w-[178px] h-[36px] text-sm overflow-hidden rounded-full bg-dsc-theme-primary text-dsc-theme-primary bg-opacity-10  ${
                  checkLink(itm.node.slug)
                    ? "bg-transparent border border-dsc-theme-primary"
                    : ""
                }`}
              >
                {checkDx(itm.node.slug)}
              </button>
            </div>
            <a
              target={checkLink(itm.node.slug) ? "_blank" : ""}
              style={{ width: "100%" }}
              href={
                checkLink(itm.node.slug)
                  ? itm.node.slug
                  : `/new/${itm.node.slug}`
              }
            >
              <div className="gap-1 contents md:flex hover:text-dsc-theme-primary">
                {itm.node.title}
                {checkLink(itm.node.slug) && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 inline md:block shrink-0"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                )}
              </div>
            </a>
          </div>
        ))}
      </div>
      <div className="flex flex-col mx-auto text-center md:flex-row md:text-left md:mt-6">
        <a
          className="flex  items-center gap-2 transition-all duration-300 text-black group 
      [&_svg]:w-8 [&_svg]:h-8 [&_svg]:text-white [&_svg]:bg-dsc-theme-accent [&_svg]:p-2 [&_svg]:rounded-full [&_svg]:transition-all [&_svg]:duration-300 [&_svg]:hover:scale-125 font-bold"
          href="/news/"
        >
          {"News一覧をみる​"}
          <img
            className="object-contain w-8 aspect-square hover:scale-[1.2] transition-all"
            src={"/icons/arrow.svg"}
            alt="more"
          />
        </a>
      </div>
    </section>
  );
};
export default Articles;
